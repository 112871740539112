import { Link, Button } from "../../../components";
import { appPaths } from "../../../config/routesConfig";
import { capitalizeWords } from "../../../helpers/dashboard";
import { AfriexReward } from "../../../types";
import { formatDate } from "../../../utils/formatDate";
import UpdateButton from "./UpdateButton";

type SummaryProp = {
  data: AfriexReward;
};

const getFieldsToDisplay = (data: SummaryProp["data"]) => {
  const status = data?.isDeactivated ? "Inactive" : "Active";
  return [
    { Name: capitalizeWords(data?.name?.replace(/_/g, " ")) },
    { Description: data.description ?? "N/A" },
    { Type: data.type?.replace(/_/g, " ")?.toUpperCase() ?? "N/A" },
    { "Expires On": formatDate(data.ttl as string) ?? "N/A" },
    { "Points Value": data.pointsValue ?? "0" + " Afriex Points" },
    { "Countries Available": data.supportedCountries?.join(", ") || "All" },
    { Status: capitalizeWords(status) },
  ];
};

const RewardSummary = ({ data }: SummaryProp) => {
  return (
    <div className="bg-white rounded-lg w-full px-5 sm:px-10 py-5 sm:py-10">
      <div className="w-full flex justify-between items-center font-semibold">
        <h2>{capitalizeWords(data?.name?.replace(/_/g, " "))} Details</h2>
      </div>
      <hr className="border-1 border-gray-200 mt-2 mb-5 w-full" />

      {getFieldsToDisplay(data)?.map((field, idx) => {
        const key = Object.keys(field)[0];
        const value = Object.values(field)[0];

        if (!value || value?.length === 0) {
          return null;
        }

        return (
          <div
            key={idx}
            className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between"
          >
            <p className="text-slate-500 text-sm w-1/3">{key}</p>
            <p className="flex w-2/3 text-sm font-medium text-gray-700 overflow-hidden whitespace-normal">
              {value}
            </p>
          </div>
        );
      })}
      <div className="text-sm text-slate-500 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full text-white pt-5 sm:pt-5">
          <div className="col-span-6">
            <div className="flex justify-end gap-4">
              <Link
                to={`/${appPaths.rewards}/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="outline" colorScheme="cyan">
                  Manage Card
                </Button>
              </Link>

              <UpdateButton initialData={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardSummary;
