import { useParams } from "react-router-dom";
import { Header, Pagination, TableSkeleton } from "../../../components";
import useReward from "../List/useReward";
import { useEffect } from "react";
import CardUpdateButton from "./CardUpdateButton";
import RewardCardTable from "./RewardCardTable";

const Details = () => {
  const params = useParams();
  const rewardId = params?.id ?? "";
  const {
    isCardLoading,
    fetchCardList,
    cardList: list,
    cardTotal: total,
    item,
  } = useReward();

  useEffect(() => {
    if (rewardId) {
      fetchCardList({}, rewardId);
    }
  }, [rewardId, fetchCardList]);

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={`${item?.name ?? ""} Reward Item`} />

      <div className="w-full flex justify-end">
        <CardUpdateButton rewardId={rewardId} />
      </div>

      <section className="pt-5 md:pt-5">
        {isCardLoading ? <TableSkeleton /> : <RewardCardTable items={list} />}
      </section>

      {list && list.length && (
        <Pagination
          onPageChange={fetchCardList as any}
          total={total}
          isSearch={false}
        />
      )}
    </main>
  );
};
export default Details;
