import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import { isEmpty } from "lodash";
import CardForm from "./CardForm";

type TierUpdateButtonProps = {
  cardId?: string;
  rewardId: string;
};

const CardUpdateButton = ({
  cardId,
  rewardId,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const label = isEmpty(cardId)
    ? "+ Upload Reward Cards"
    : "Remove From Reward";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <CardForm onClose={toggleFullScreen} rewardId={rewardId} />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {label}
    </Button>
  );
};

export default CardUpdateButton;
