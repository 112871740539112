import {
  Modal,
  Button,
  SpinningLoader,
  Input,
  Select,
  CheckBox,
  CurrencyPicker,
} from "../../../components";
import {
  AfriexRateInfo,
  AfriexRateTierCode,
  AfriexTier,
  SupportedCurrencies,
  TransactionChannels,
} from "../../../types";
import useTierUpdate from "./useTierUpdate";
import { TierActionType } from "./types";
import { isEmpty } from "lodash";
import ArbitrageTable from "../Upload/ArbitrageTable";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";
import { applyDiscountToRates } from "../../../utils/rates/applyDiscountToRates";
import { toTitleCase } from "../../../utils/services/toTitleCase";
import RateWidget from "./RateWidget";

type TierFormProps = {
  onClose: VoidFunction;
  initialData?: AfriexTier;
  rateInfo?: AfriexRateInfo;
};

const TierForm = ({ onClose, initialData, rateInfo }: TierFormProps) => {
  const {
    arbitrageResult,
    isArbitrageDetected,
    isArbitrageChecked,
    handleToleranceChange,
    checkIfRateTierHasArbitrage,
    dispatch,
    isLoading,
    handleSubmit,
    shouldShowArbitrageTable,
    setShouldShowArbitrageTable,
    tier,
    tolerance,
  } = useTierUpdate(onClose, initialData, rateInfo);

  const {
    discount,
    isActive,
    currencies,
    allowedSourceChannels,
    allowedDestChannels,
    name,
    code,
    transactionValueThreshold,
    transactionCountThreshold,
    type,
  } = tier;

  const updateAction = {
    type: TierActionType.UPDATE_TIER,
    payload: {},
  };
  const updateLabel = isEmpty(initialData)
    ? "Create New Promo Rate +"
    : "Update Promo Tier";
  const submitLabel = isEmpty(initialData) ? "Submit" : "Update";
  const isDisabled = isEmpty(tier);

  const typeSelectionOptions = [
    { value: "", label: "--Select Type--" },
    { value: "promotion", label: "Promotion" },
    { value: "tier", label: "Tier" },
    { value: "paymentMethod", label: "Payment Method" },
  ];
  const codeSelectionOptions = [
    { value: "", label: "--Select Code--" },
    ...Object.values(AfriexRateTierCode).map((code) => ({
      value: code,
      label: toTitleCase(code?.replace("_", " ")),
    })),
  ];
  const disallowedChannels = [
    TransactionChannels.INTERNAL,
    TransactionChannels.WIDGET,
    TransactionChannels.CRYPTO,
  ];
  const channelOptions = Object.values(TransactionChannels)
    .filter((x) => !disallowedChannels.includes(x))
    ?.map((channel) => ({
      value: channel,
      label: toTitleCase(channel?.replace("_", " ")),
    }));
  const sourceSelectionOptions = [
    { value: "", label: "--Select Channel--" },
    ...channelOptions,
  ];
  const destSelectionOptions = [
    { value: "", label: "--Select Channel--" },
    ...channelOptions,
  ];

  const defaultCode =
    codeSelectionOptions.find((option) => option.value === code) ??
    codeSelectionOptions[0];

  const defaultType =
    typeSelectionOptions.find((option) => option.value === type) ??
    typeSelectionOptions[0];
  const defaultCodeValue = defaultCode.value as string;
  const discountedRates = applyDiscountToRates(
    rateInfo?.appRates ?? [],
    tier.discount ?? 0
  );
  const discountedRatesMap = convertRatesListToMap(discountedRates);

  const shouldShowArbitrageInfo =
    isArbitrageDetected && isArbitrageChecked && shouldShowArbitrageTable;
  const arbitrageButtonLabel = shouldShowArbitrageInfo
    ? "Hide Arbitrage Info"
    : "Check Arbitrage";
  const arbitrageButtonHandler = shouldShowArbitrageInfo
    ? () => setShouldShowArbitrageTable(false)
    : () => checkIfRateTierHasArbitrage();

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">{updateLabel}</h3>
        {arbitrageResult && arbitrageResult?.currencies?.length && (
          <ArbitrageTable
            arbitrageResult={arbitrageResult}
            rates={discountedRatesMap}
            tolerance={tolerance}
          />
        )}
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(tier as AfriexTier) as any;
              }}
              className="flex flex-col space-y-5"
            >
              <RateWidget
                rates={rateInfo?.appRates ?? []}
                discount={discount ?? 0}
              />
              <div className="mt-5">
                <Input
                  label="Name"
                  value={name}
                  type="text"
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        name: e.target?.value ?? name,
                      },
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <Input
                  label="Discount value (%)"
                  min="-1"
                  max="100"
                  step="0.0001"
                  type="number"
                  value={discount}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        discount: e.target?.value
                          ? Number(e.target?.value)
                          : discount,
                      },
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <Input
                  label="Max Allowed Transactions Count"
                  min="0"
                  type="number"
                  value={transactionCountThreshold}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        transactionCountThreshold: e.target?.value
                          ? Number(e.target?.value)
                          : transactionCountThreshold,
                      },
                    })
                  }
                />
              </div>
              <div className="mt-5">
                <Input
                  label="Max Allowed Transactions Volume (Optional)"
                  min="0"
                  type="number"
                  value={transactionValueThreshold}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        transactionValueThreshold: e.target?.value
                          ? Number(e.target?.value)
                          : transactionValueThreshold,
                      },
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <Select
                  label="Code"
                  options={codeSelectionOptions}
                  defaultValue={defaultCodeValue}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        code: e.target?.value ?? (defaultType.value as any),
                      },
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <ul className="flex flex-col sm:flex-row">
                  {allowedSourceChannels?.map((channel, index) => (
                    <li
                      className="inline-flex items-center gap-x-2.5 py-2 px-2 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg"
                      key={index}
                    >
                      {channel}
                      <Button
                        variant={"ghost"}
                        onClick={() => {
                          dispatch({
                            type: TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD,
                            payload: channel,
                          });
                          return false;
                        }}
                      >
                        {" "}
                        x
                      </Button>
                    </li>
                  ))}
                </ul>
                <Select
                  label="Allowed Source Payment Channels (Optional)"
                  options={sourceSelectionOptions}
                  // defaultValue={allowedSourceChannels}
                  onChange={(e: any) =>
                    dispatch({
                      type: allowedSourceChannels?.includes(e.target?.value)
                        ? TierActionType.REMOVE_SUPPORTED_SOURCE_PAYMENT_METHOD
                        : TierActionType.ADD_SUPPORTED_SOURCE_PAYMENT_METHOD,
                      payload: e.target?.value ?? (defaultType.value as any),
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <ul className="flex flex-col sm:flex-row">
                  {allowedDestChannels?.map((channel, index) => (
                    <li
                      className="inline-flex items-center gap-x-2.5 py-2 px-2 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg"
                      key={index}
                    >
                      {channel}
                      <Button
                        variant={"ghost"}
                        onClick={() => {
                          dispatch({
                            type: TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD,
                            payload: channel,
                          });
                          return false;
                        }}
                      >
                        {" "}
                        x
                      </Button>
                    </li>
                  ))}
                </ul>
                <Select
                  label="Allowed Destination Payment Channels (Optional)"
                  options={destSelectionOptions}
                  defaultValue={allowedDestChannels}
                  onChange={(e: any) =>
                    dispatch({
                      type: allowedDestChannels?.includes(e.target?.value)
                        ? TierActionType.REMOVE_SUPPORTED_TARGET_PAYMENT_METHOD
                        : TierActionType.ADD_SUPPORTED_TARGET_PAYMENT_METHOD,
                      payload: e.target?.value ?? (defaultType.value as any),
                    })
                  }
                />
              </div>
              <div className="mt-5">
                <ul className="flex flex-col sm:flex-row">
                  {currencies?.map((currencyCode, index) => (
                    <li
                      className="inline-flex items-center gap-x-2.5 py-2 px-2 text-sm font-medium bg-blue-100 border border-gray-200 text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg"
                      key={index}
                    >
                      {currencyCode}
                      <Button
                        variant={"ghost"}
                        onClick={() => {
                          dispatch({
                            type: TierActionType.REMOVE_SUPPORTED_CURRENCY,
                            payload: currencyCode,
                          });
                          return false;
                        }}
                      >
                        {" "}
                        x
                      </Button>
                    </li>
                  ))}
                </ul>
                <CurrencyPicker
                  label="Add Destination Currency"
                  value={currencies?.[currencies?.length - 1] ?? ("" as any)}
                  onChange={(value: SupportedCurrencies) =>
                    dispatch({
                      type: TierActionType.ADD_SUPPORTED_CURRENCY,
                      payload: value,
                    })
                  }
                />
              </div>
              <div className="mt-5">
                <CheckBox
                  label="Active"
                  type="checkbox"
                  checked={Boolean(isActive)}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        isActive: Boolean(e.target?.checked ?? isActive),
                      },
                    })
                  }
                />
              </div>
              {shouldShowArbitrageInfo && (
                <ArbitrageTable
                  arbitrageResult={arbitrageResult!}
                  rates={discountedRatesMap}
                  tolerance={tolerance}
                  onToleranceChange={handleToleranceChange}
                />
              )}

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button type="button" onClick={onClose} variant="outline">
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="w-full"
                  onClick={arbitrageButtonHandler}
                  variant="outline"
                >
                  {arbitrageButtonLabel}
                </Button>
                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  {submitLabel}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TierForm;
