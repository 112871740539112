import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import UpdateForm from "../Card/UpdateForm";
import { AfriexReward } from "../../../types";
import { isEmpty } from "lodash";
import useReward from "./useReward";

type RewardButtonProps = {
  initialData?: AfriexReward;
};

const RewardButton = ({ initialData }: RewardButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const { handleAdd, handleUpdate } = useReward();
  const label = !isEmpty(initialData) ? "Update Reward" : "Add New Reward";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <UpdateForm
      onClose={toggleFullScreen}
      handleSave={!isEmpty(initialData) ? handleUpdate : handleAdd}
      initialData={initialData}
    />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {label}
    </Button>
  );
};

export default RewardButton;
