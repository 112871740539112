import { Header, Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import UpdateButton from "./UpdateButton";
import RewardsTable from "./RewardsTable";
import useReward from "./useReward";

const RewardsList = () => {
  const { isLoading, isSearch, fetchList, total, list } = useReward();
  const totalFigure = total ? ` (${total.toLocaleString()})` : "";

  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title={"Manage Reward List" + totalFigure} />
      <div className="w-full flex justify-end">
        <UpdateButton />
      </div>

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <RewardsTable items={list} />}
      </section>

      {list && list.length && (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={isSearch}
        />
      )}
    </main>
  );
};

export default RewardsList;
