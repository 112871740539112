import { useState } from "react";
import { Modal, Button, SpinningLoader, TextArea } from "../../../components";
import useReward from "../List/useReward";
import { isEmpty } from "lodash";

type RewardFormProps = {
  onClose: VoidFunction;
  cardId?: string;
  rewardId: string;
};

const TierForm = ({ onClose, cardId, rewardId }: RewardFormProps) => {
  const [codes, setCodes] = useState("");
  const { isCardLoading: isLoading, handleAddCards } = useReward();

  const updateLabel = isEmpty(cardId)
    ? "Add Cards To Reward Item +"
    : "Remove Cards From Reward Item";
  const submitLabel = isEmpty(cardId) ? "Submit" : "Remove";
  const isDisabled = isEmpty(codes);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">{updateLabel}</h3>

        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddCards(codes, rewardId) as any;
              }}
              className="flex flex-col space-y-5"
            >
              <div className="mt-5">
                <TextArea
                  label="Enter card codes"
                  placeholder="Enter card codes one on each line"
                  value={codes ?? ""}
                  onChange={(e: any) => setCodes(e.target?.value ?? codes)}
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button type="button" onClick={onClose} variant="outline">
                  Cancel
                </Button>

                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="outline"
                  className="bg-indigo-200 hover:g"
                >
                  {submitLabel}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default TierForm;
