import { AfriexUserQueryParams } from "../types/User";
import axios from "../api/axios";
import {
  AfriexLoginRequest,
  AfriexNonceResponse,
  AfriexPaginatedResponse,
  AfriexPerson,
  AfriexUserBalances,
} from "../types";
import { replaceUnderScoreIds } from "../utils/replaceUnderScoreIds";
import {
  AfriexSecurityNoteRequest,
  AfriexSecurityNotesQueryParams,
} from "../types/Notes";
import { UserUpdateBody } from "../types/UserUpdateBody";
import { AfriexLog } from "../types/AfriexLog";
import { AfriexDispute, AfriexDisputeCount } from "../types/AfriexDispute";

const columns = [
  "name",
  "currentCountry",
  "currentEmail",
  "currentPhone",
  "kyc",
  "isPhoneVerified",
  "isSecurityFlagged",
  "createdAt",
  "externalAccounts",
];

export const userService = {
  getUsers: async (
    filters: AfriexUserQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexPerson[]>> => {
    const queryParams = {
      page: 0,
      limit: 10,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/users?${urlParams}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexPerson>(response.data?.data);
    const userListData = { ...response.data, data };
    return userListData;
  },
  getUser: async (userId: string) => {
    const response = await axios.get(`/v2/admin/users/${userId}`);
    return response.data;
  },
  getUserByBankAccountNumber: async (bankAccountNumber: string) => {
    const params = { bankAccountNumber };
    const urlParams = new URLSearchParams(params).toString();
    const response = await axios.get(
      `/v2/admin/users/payment-method?${urlParams}`
    );
    return response.data;
  },
  updateUser: async (userId: string, body: Partial<UserUpdateBody>) => {
    const response = await axios.patch(`/v2/admin/users/${userId}`, body);
    return response.data;
  },
  sendLogin: async (body: AfriexLoginRequest): Promise<AfriexNonceResponse> => {
    const response = await axios.post(`/v2/admin/users/login`, body);
    return response.data;
  },
  searchUser: async (searchTerm: string): Promise<AfriexPerson> => {
    const params = {
      searchTerm,
      columns,
    } as any;
    const url = `/v2/admin/users/search?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  searchLog: async (searchTerm: string): Promise<AfriexLog> => {
    const params = {
      searchTerm,
      columns,
    } as any;
    const url = `/v2/admin/logs/search?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    return response.data;
  },
  searchAutoComplete: async (searchTerm: string): Promise<AfriexPerson[]> => {
    const params = {
      searchTerm: searchTerm?.trim()?.toString(),
      columns,
    } as any;

    const url = `/v2/admin/users/autocomplete?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexPerson>(response.data ?? []);

    return data;
  },
  searchLogAutoComplete: async (searchTerm: string): Promise<AfriexLog[]> => {
    const params = {
      searchTerm: searchTerm?.trim()?.toString(),
      columns,
    } as any;

    const url = `/v2/admin/logs/autocomplete?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexLog>(response.data ?? []);
    return data;
  },
  getUserWalletBalance: async (userId: string): Promise<AfriexUserBalances> => {
    const response = await axios.get(`/v2/admin/users/balance/${userId}`);
    const balances = response.data;
    const filteredBalances = Object.entries(balances).reduce(
      (acc, [key, value]) => {
        if (value !== 0) {
          acc[key as string] = value;
        }
        return acc;
      },
      {} as any
    );
    return filteredBalances;
  },
  getUserSecurityNotes: async (
    userId: string,
    filters: AfriexSecurityNotesQueryParams
  ) => {
    const queryParams = {
      page: 0,
      limit: 6,
    } as any;

    const params = { ...queryParams, ...filters };
    const urlParams = new URLSearchParams(params).toString();
    const url = `/v2/admin/security-notes/user/${userId}?${urlParams}`;
    const response = await axios.get(url);
    return response.data;
  },
  createSecurityNote: async (note: AfriexSecurityNoteRequest) => {
    const response = await axios.post(`/v2/admin/security-notes`, note);
    return response.data;
  },
  blockUser: async (
    userId: string,
    isBlocked: boolean,
    note?: string,
    reason?: string,
    unblockReason?: string
  ) => {
    const response = await axios.post(`/v2/admin/users/block/${userId}`, {
      isSecurityEnabled: !isBlocked,
      note,
      reason: !isBlocked ? reason : undefined,
      unblockReason: isBlocked ? unblockReason : undefined,
    });
    return response.data;
  },
  getUserLogs: async (
    userId: string,
    { limit = 10, page = 0 }: AfriexUserQueryParams
  ): Promise<AfriexPaginatedResponse<AfriexLog[]>> => {
    const params = {
      page,
      limit,
    } as any;
    const url = `/v2/admin/logs/${userId}?${new URLSearchParams(
      params
    ).toString()}`;
    const response = await axios.get(url);
    const data = replaceUnderScoreIds<AfriexLog>(response.data?.data);
    return {
      data,
      total: response.data?.total,
      page,
    };
  },
  getDisputes: async ({
    limit = 10,
    page = 0,
  }: AfriexUserQueryParams): Promise<
    AfriexPaginatedResponse<AfriexDispute[]>
  > => {
    const params = {
      limit,
      page,
    } as any;
    const url = `/v2/admin/disputes?${new URLSearchParams(params).toString()}`;
    const response = await axios.get(url);
    return {
      data: response.data.data,
      total: response.data.total,
      page,
    };
  },
  updateDispute: async (
    disputeId: string,
    updateData: {
      status?: string;
      internalData?: {
        providerStatus?: string;
        providerReason?: string;
      };
      notes?: {
        note: string;
        createdAt: Date;
        updatedAt: Date;
      }[];
    }
  ): Promise<AfriexDispute> => {
    const url = `/v2/admin/disputes/${disputeId}`;
    const response = await axios.patch(url, updateData);
    return response.data;
  },
  countDisputes: async (): Promise<AfriexDisputeCount> => {
    const url = "/v2/admin/disputes/count";
    const response = await axios.get<AfriexDisputeCount>(url);
    return response.data;
  },
};
